<template>
  <div
    class="
      flex flex-row justify-center
      text-base
      absolute z-10 bg-white box-border h-[217px] w-full border-2">
    <button class="m-3 h-10" v-for="(menu, index) in this.menu_list[this.menu_num]" :key="index">
      {{ menu }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'MenuList',
  props: {
    menu_num: Number,
  },
  data() {
    return {
      menu_list: [
        [
          '탈잉이벤트',
          '튜터전자책',
          '투잡',
          '방송댄스',
          '엑셀',
          '퍼스널컬러',
          '마케팅',
          '이색 액티비티',
          '사진',
          '보컬',
          '영상편집',
          '이색취미',
          '웹개발',
          '영어회화',
        ],
        [
          '메이크업', '퍼스널컬러', '패션', '셀프케어', 'PT/GX',
        ],
        [
          '방송댄스', '댄스', '연기/무용', '스포츠/레저', '이색 액티비티',
        ],
        [
          '인문/교양',
          '인테리어',
          '반려동물',
          '부모/육아',
          '출판/글쓰기',
          '사주/타로',
          '심리상담',
        ],
        [
          '이색취미',
          '사진',
          '취미미술',
          '디지털드로잉',
          '요리/베이킹',
          '커피/차/술',
          '보컬',
          '악기',
          '작곡/디제잉',
          '캘리그래피',
          '플라워',
          '조향/캔들/비누',
          '가죽/목공/도예',
        ],
        [
          '투잡', '마케팅', '주식투자', '부동산', '금융지식', '창업',
        ],
        [
          '실무역량',
          '마케팅',
          '취업/이직/진로',
          '엑셀',
          '파워포인트',
          '스피치',
          '데이터분석',
          '웹개발',
          '컴퓨터공학',
          '자격증/시험',
        ],
        [
          '건축',
          '그래픽디자인',
          'UX/UI디자인',
          '제품디자인',
          '영상편집',
          '영상제작',
        ],
        [
          '영어회화', '중국어회화', '일본어회화', '어학자격증', '기타 외국어',
        ],
      ],
    };
  },
  created() {
  },
};
</script>

<style>

</style>
