<template>
  <router-view name="main"></router-view>
</template>

<script>
export default {
  name: 'App',
  components: {},
};
</script>

<style>
#wrap {
  min-width: 1200px;
}
</style>
